
import { Component, Emit, ModelSync, Prop, Vue } from 'vue-property-decorator';
import DttIcon from '@/components/Icon/index.vue';
import { IconName } from '@/components/Icon/types';
import { InputSize } from '@/components/Input/types';
import { MaskOptions } from 'maska';

@Component<Input>({
  name: 'DttInput',
  components: { DttIcon },
})
export default class Input extends Vue {
  @ModelSync('value', 'change') modelValue?: string | number | null;
  @Prop({ type: String, required: true, default: '' }) name!: string;
  @Prop({ type: String, default: 'text' }) type!: string;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Prop({ type: Boolean, default: false }) readonly!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) resizable!: boolean;
  @Prop({ type: Number, default: 1 }) textAreaRows!: string;
  @Prop({ type: Boolean, default: false }) clearable!: boolean;
  @Prop({ type: Boolean, default: false }) centeredCursor!: boolean;
  @Prop({ type: [Boolean, String], default: null }) validation!:
    | boolean
    | string
    | null;
  @Prop({ default: '' }) placeholder!: string | any;
  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: String, default: '' }) help!: string;
  @Prop({ type: String, default: 'md' }) size!: InputSize;
  @Prop({ type: String, default: '' }) iconLeft!: IconName;
  @Prop({ type: String, default: '' }) iconRight!: IconName;
  @Prop({ type: Array }) errors?: { message: string }[];
  @Prop({ type: Boolean, default: false }) clickableIcons!: boolean;
  @Prop({ type: Boolean, default: false }) isDirtyInitial!: boolean;
  @Prop({ type: Object }) maskOptions?: MaskOptions;
  isDirty = false;

  get hasValue() {
    return (
      this.modelValue !== '' &&
      this.modelValue !== null &&
      this.modelValue !== undefined
    );
  }
  get iconSize() {
    switch (this.size) {
      case 'sm':
        return '20px';
      default:
        return '24px';
    }
  }

  get errorsView() {
    return (
      this.errors?.map(({ message }) => message).join('<br />') ||
      this.validation ||
      ' '
    );
  }

  get hasErrorState() {
    return (
      this.isDirty &&
      (typeof this.validation === 'string' || !!this.errors?.length)
    );
  }

  get hasSuccessState() {
    return this.isDirty && this.errors?.length === 0;
  }

  get inputClasses() {
    return [
      this.hasErrorState && 'error',
      this.hasSuccessState && 'success',
      this.centeredCursor && 'centered-cursor',
    ];
  }

  clearClick() {
    this.modelValue = '';
  }

  @Emit()
  leftIconClick() {
    return;
  }

  @Emit()
  rightIconClick() {
    return;
  }

  @Emit()
  onFocus() {
    return;
  }

  beforeMount() {
    this.isDirty = this.isDirtyInitial;
  }
}
