
import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator';
import { getElementViewportArea, ViewportArea } from '@/utils/screen';
import DttIcon from '@/components/Icon/index.vue';
import DttCheckbox from '@/components/Checkbox/index.vue';
import DttTypo from '@/components/Typo/index.vue';
import {
  ContextMenuItem,
  ContextMenuSize,
} from '@/components/ContextMenu/types';
import DttInput from '@/components/Input/index.vue';

@Component({
  name: 'DttContextMenu',
  components: { DttInput, DttTypo, DttCheckbox, DttIcon },
})
export default class ContextMenu extends Vue {
  @ModelSync('value', 'change', { default: null })
  modelValue!: ContextMenuItem | ContextMenuItem[] | any | any[] | null;
  @Prop({ type: Array, default: () => [] })
  options!: ContextMenuItem[];
  @Prop({ type: String, default: 'md' }) size!: ContextMenuSize;
  @Prop({ type: Boolean, default: false }) selectable!: boolean;
  @Prop({ type: Boolean, default: false }) splitted!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) useValue!: boolean;
  @Prop({ type: Boolean, default: false }) searchable!: boolean;
  @Prop({ type: Boolean, default: false }) fixed!: boolean;
  @Prop({ type: String }) searchKey!: any;
  @Prop({ type: String, default: '' }) searchPlaceholder: any;

  TARGET_MARGIN = 10;
  searchValue = '';
  isOpen = false;
  area: ViewportArea = 'left-top';
  requestAnimationId: number | null = null;

  get viewportAreaClass() {
    return `dtt-panel--${this.area}`;
  }

  get itemClasses() {
    return [this.splitted && 'splitted', this.size];
  }

  get iconSize() {
    switch (this.size) {
      case 'sm':
        return '18px';
      default:
        return '24px';
    }
  }

  get filteredOptions() {
    if (this.searchable && this.searchValue) {
      const key: keyof ContextMenuItem = this.searchKey || 'label';
      return this.options.filter((opt) => {
        const value = opt[key];
        if (typeof value === 'string') {
          return value
            .toLowerCase()
            .startsWith(this.searchValue.toLowerCase().trim());
        }
        return true;
      });
    } else {
      return this.options;
    }
  }

  toggle() {
    if (this.disabled) return;
    this.isOpen = !this.isOpen;
    if (this.fixed) {
      if (this.isOpen) {
        this.requestAnimationId = window.requestAnimationFrame(
          this.replacePanel
        );
      } else if (this.requestAnimationId) {
        window.cancelAnimationFrame(this.requestAnimationId);
        this.requestAnimationId = null;
      }
    } else {
      this.area = getElementViewportArea(this.$refs.target as Element).area;
    }
  }

  replacePanel() {
    const target = this.$refs.target as HTMLElement;
    const panel = this.$refs.panel as HTMLElement;
    if (!target || !panel) {
      this.requestAnimationId = window.requestAnimationFrame(this.replacePanel);
      return;
    }
    const { width: panelWidth, height: panelHeight } =
      panel.getBoundingClientRect();
    const {
      left,
      top,
      bottom,
      width: targetWidth,
    } = target.getBoundingClientRect();
    const { x, y } = getElementViewportArea(this.$refs.target as Element);
    if (x === 'left') panel.style.left = `${left}px`;
    else panel.style.left = `${left - panelWidth + targetWidth}px`;
    if (y === 'top') panel.style.top = `${bottom + this.TARGET_MARGIN}px`;
    else panel.style.top = `${top - panelHeight - this.TARGET_MARGIN}px`;
    this.requestAnimationId = window.requestAnimationFrame(this.replacePanel);
  }

  isActive(item: ContextMenuItem | any) {
    if (this.modelValue === null) return false;
    const checkingValue = this.useValue ? item.value : item;
    if (this.modelValue instanceof Array) {
      return !!this.modelValue.find((obj: ContextMenuItem | any) => {
        if (this.useValue) return obj === checkingValue;
        return obj.value === checkingValue.value;
      });
    }
    return this.modelValue === checkingValue;
  }

  onMenuClick(item: ContextMenuItem | any) {
    const checkingValue = this.useValue ? item.value : item;
    if (!this.selectable) {
      this.modelValue =
        this.modelValue === checkingValue ? null : checkingValue;
      this.isOpen = false;
      return;
    }

    if (this.modelValue instanceof Array) {
      const index = this.modelValue.findIndex((obj) => {
        if (this.useValue) return obj === checkingValue;
        return obj.value === checkingValue.value;
      });
      if (index === -1) {
        this.modelValue = [...this.modelValue, checkingValue];
        return;
      }
      const newModelValue = [
        ...this.modelValue.slice(0, index),
        ...this.modelValue.slice(index + 1),
      ];
      this.modelValue = newModelValue.length ? newModelValue : null;
    } else {
      this.modelValue = [checkingValue];
    }
  }

  outsideClickHandler(e: MouseEvent) {
    const el = this.$refs.el as Element;
    if (!el.contains(e.target as Node)) {
      this.isOpen = false;
    }
  }

  mounted() {
    document.addEventListener('click', this.outsideClickHandler);
  }

  beforeDestroy() {
    document.removeEventListener('click', this.outsideClickHandler);
    if (this.requestAnimationId) {
      window.cancelAnimationFrame(this.requestAnimationId);
      this.requestAnimationId = null;
    }
  }
}
